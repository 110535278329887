
.updates-view-container {
    .add-new-holder {
      padding: 30px 0;
    }

    .update-progress {
        min-width: 120px;
        display: flex;
        align-items: center;
        .linear-progress {
            flex: 4 4 auto;
        }
        .progress-num {
          margin-left: 16px;
          flex: 1 0 100px;
        }
    }
}
