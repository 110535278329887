
.toolbar-container {
  margin: 30px -16px;
  padding: 0 16px 16px 16px;

  h4 {
    padding-top: 16px;
    margin-bottom: 0;
  }

  .text-input-fields {
    flex: 5 5 80%;
    .tpicker {
      margin-right: 20px;
      max-width: 40%;
      min-width: 100px;
    }
  }
}
