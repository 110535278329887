
.loc-watch-dialog-content {
    max-width: 411px;
    min-width: 256px;
    @media (min-width: 410px) {
        min-width: 360px;
    }

    .loc-watch-dialog-title {
        background-color: white;
    }
}
