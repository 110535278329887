
.host-form-main {
  font-size: 16px;

  .stepper-container {
    margin: 10px -20px;

    @media (min-width: 600px) {
      margin: 10px -24px;
    }

    //background-color: #efefef !important;
    .host-form-stepper {
      max-width: 650px;
      padding-left: 10px !important;
      padding-right: 10px !important;
      margin-left: auto;
      margin-right: auto;
      //background-color: #efefef !important;
    }
  }
}
