
.fuente-filters {
    padding : 30px 0;
    display: flex;
    .filters-container {
        flex: 4 1 auto;
    }
    .menu-container {
        flex: 0 0 48px;
    }
}
