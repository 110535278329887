.stat-row {
    margin: 20px 0;
    display: flex;
    align-items: center;

    &.center {
        justify-content: center;
    }

    .stat-item {
        text-align: center;
        border-left: 1px solid gray;
        margin-bottom: 15px;
        flex: 0 0 33.3%;

        &:first-child {
            border-left: 0;
        }
        .stat-label {
            font-size: 13px;
            color: #999;
        }
        .stat-value {
            margin-top: 10px;
            font-size: 24px;
        }
    }

    &.stat-row-4 {
        .stat-item {
            flex: 0 0 25%;
        }
        @media (max-width: 580px) {
            flex-wrap: wrap;
            .stat-item {
                flex: 0 0 50%;
                &:nth-child(3) {
                    border-left: 0;
                }
            }
        }
    }
}
