
.success {
  font-size: 16px;
  h1 {
    margin-bottom: 5px;
  }
  .emoji {
    margin : 0;
    font-size: 45px;
  }
}
