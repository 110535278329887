
.main-tabs {
    background-color: #eee;
}

.main-tabs-container {
    @media (max-width: 470px) {
        //margin-left: -16px;
        //margin-right: -16px;
    }
}
