

.form {

  h2 {
    margin-top: 0;
    text-align: center;
  }

  .stepper-container {
    //background-color: #efefef;
    padding: 16px;
    margin-left: -24px;
    margin-right: -24px;
    margin-top: 10px;
    margin-bottom: 10px;
    .stepper-inner {
      max-width: 550px;
      margin-right: auto;
      margin-left: auto;
      .form-stepper {
        //background-color: #efefef;
      }
    }

    @media (max-width: 600px) {
        margin-left: -20px;
        margin-right: -20px;
    }
  }

  .send-form-container {
    text-align: center;
    padding-top: 20px;
  }

  .contact-success {
    display: flex;
    margin: 30px 10px;
    align-items: center;
    .check-icon {
      flex: 0 0 60px;
      fill: green;
      width: 40px;
      height: 40px;
    }
    .msg {
      padding-left: 10px;
      font-size: 15px;
      font-weight: bold;
      color: green;
      flex: 1 1 auto;
    }
  }

  .form-group {
    padding-bottom: 20px;
    .form-group-title {

    }
    .form-group-desc {
        font-size: 15px;
        margin-bottom: 30px;
    }
    .form-group-content {
      .form-item {
        display: flex;
        margin-bottom: 30px;
      }
      .form-item-expand-content {
        padding-left: 30px;
        padding-bottom: 30px;
        .form-item-child-inline {
          margin-right: 15px;
        }
        &.multiselect-expand {
          padding-top: 30px;
        }
      }

      .form-item-group {
        padding-top: 15px;
        .form-item-group-label {
          font-size: 15px;
        }
        .form-item-group-content {
          padding-top: 5px;
          .inline-item {
            margin-right: 15px;
          }
        }
      }
    }

    .form-subgroup {
      padding-top: 25px;
      .form-subgroup-title {
        font-size: 15px;
        font-weight: bold;
      }
      .form-subgroup-content {
        padding-top: 10px;
      }
    }
  }

  .run-test-button {
    text-align: center;
  }

  .seguir-button {
    padding-top: 20px;
    text-align: center;
  }

  .error {
    color: #f44336;
    font-size: 15px;
    padding: 30px 0 ;
  }


  .text-number-select {
    min-width: 150px
  }
}
