.drop-zone {
  margin-top: 5px;
  border: 3px dashed #ccc;
  position: relative;
  cursor: pointer;

  .drop-zone-content{
    fill: #ccc;
    color: #999;
    text-align: center;
    padding-top: 40px;
    padding-bottom: 40px;

    .drop-zone-icon {
      width: 48px;
      height: 48px;
    }
    .drop-zone-label {

    }
  }

  &.active, &:active {
    border: 3px dashed #777;
  }
  &:focus {
    outline: none !important;
  }
}
