
.login-form-paper {
  margin-top: 50px;
  padding: 30px 15px 40px 15px;

  .snack-content {
    margin-left: auto;
    margin-right : auto;
  }

  .welcome {
    text-align: center;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .login-button-container {
      margin-top: 25px;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
  }
}
