

.status-indicators {
    display: flex;
    align-items: center;
    padding: 0px 0 25px;
    .indicator {
        padding-right: 20px;
        &:last-child {
            padding-right: 0;
        }

        .constate {
          margin-left: 7px;
        }

        &.saldo {
            display: flex;
            .saldo-label {
               font-size: 15px;
               margin-right: 5px;
            }
            .saldo-value {
               font-size: 15px;
               font-weight: 500;
            }
        }
    }
}
