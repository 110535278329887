
.multi-checkbox-field {

  display: flex !important;
  margin-top: 20px;

  .multi-checkbox-field-group-label-bottom {
    margin-left: -16px;
    margin-right: -16px;
  }

  .multi-checkbox-error-msg {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .multi-checkbox-field-label {
    margin-bottom: 15px;
  }

  .multi-checkbox-item-label-bottom {
    max-width: 80px;
    //min-width: 100px;
    text-align: center;
    span {
      font-size: 14px !important;
    }
  }
}
