
.home-view-container {
    padding-bottom: 80px;

    .title-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .add-new-holder {
            margin-left: 16px;
            padding: 0px 0;
        }
    }

    .filters {
        margin-bottom: 16px;
    }


    .fuente-sec-item {
        padding: 10px 0 0 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .sec-item {
            margin-right: 10px;
            white-space: nowrap;
            min-height: 24px;
            line-height: 24px;
            margin-bottom: 10px;
            max-width: 200px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
