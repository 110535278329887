.address-field {
  position: relative;

  .address-field-suggestions {
    z-index: 20;
    position: absolute;
    top: 48px;
    left: 0;
    right: 0;
  }

  .location-point-map-edit {
      margin-bottom: 24px;
  }
}
