
.bplot {
  .bplot-line {
    stroke: rgb(63, 81, 181);
    stroke-width: 2;
    &.median {
      stroke: red;
    }
  }
  .bplot-box {
    fill: none;
    stroke-width: 2;
    stroke: rgb(63, 81, 181);
  }

  .bplot-outlier {
    stroke-width: 0;
    fill: rgb(63, 81, 181);
  }
}

.boxplot-container {
  padding-left: 36px;
  padding-right: 28px;
}
