
.location-point-map {
    width: 100%;
    min-height: 120px;
    position: relative;
    background-color: #ddd;

    .map-fallback-container {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        max-width: 120px;
        z-index: 1;

        .location-icon {
            width: 48px;
            height: 48px;
        }
        .map-fallback-msg {
            font-size: 12px;
            color: white;
        }
    }

    .map-div {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }
}
