
.image-upload-thumb {
  padding-top: 30%;
  position: relative;
  display: inline-block;
  width: 30%;

  .img-holder {
    position: absolute;
    top: 4px;
    right: 4px;
    left: 4px;
    bottom: 4px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .upload-progress {
    position: absolute;
    bottom: 0;
    left: 4px;
    right: 4px;
  }

  .interaction-surface {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      .delete-button {
        display: none;
      }
      &:hover {
        background-color: rgba(255,255,255, 0.8);
        .delete-button {
          display: block;
          position: absolute;
          top: 4px;
          right: 4px;
        }
      }
  }
}
