.location-container {
  margin: 10px 0px;
  margin-bottom: 80px !important;

  .location-edit-btn {
    float: right;
  }

  .h1-title {
    margin-bottom: 5px;
  }

  .fuente-desc {
    margin-bottom: 20px;
  }

  .settings-table {
      padding-bottom: 15px;
      max-width: 450px;
  }

  .download-btn-container {
    margin: 16px 0;
  }

  .chart-section {
    padding: 15px 0;
    .chart-title {
      color: #999;
      //margin-left: 30px;
    }
  }
}
