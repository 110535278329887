.h1-title {
  margin-bottom: 30px;
}

.klara-form, .klara-dialog-form {
  .form-field {
    padding-bottom: 30px;
    overflow: hidden;
  }
}

.klara-dialog-form {
  overflow-x: hidden;
}

.klara-dialog-container {
  position: relative;
  .dialog-loading-container {
      z-index: 20;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: rgba(255,255,255, 0.7);
      .loading-elem {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
  }
}

.klara-main-container {
  //margin: 16px;
  margin-bottom: 60px;
}

.tab-content-container {
  padding-top: 30px;
}

.info-item {
    padding: 12px 0;
    min-height: 50px;
    .info-label {
        font-size: 14px;
        font-weight: bold;
        color: #999;
        min-width: 70px;
    }
    .info-item {
        margin-top: 3px;
        font-size: 16px;
    }

    &.inline {
        display: flex;
        align-items: center;
        .info-label {
            margin-right: 16px
        }
    }
}

.p-desc {
  margin-bottom: 20px;
}

.h3-subtitle {
    color: #999;
}


.subtitle-fields {
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    .subtitle-btn-link {
        font-size: 16px;
        font-weight: 500;
        color: #AAA;
        margin-right: 30px;
    }
}
