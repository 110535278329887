
.field-item-display {

    padding: 5px 0 10px;
    display: flex;
    align-items: flex-start;

    .field-item-display-label {
        font-weight: bold;
        padding-bottom: 4px;
        flex: 0 0 150px;
        font-size: 1rem;
    }
    .field-item-display-content {
        flex: 1 1 auto;
        font-size: 1rem;
    }

    @media (max-width: 480px) {
        flex-wrap: wrap;
        .field-item-display-label {
            width: 100%;
            flex: 0 0 100%;
        }
    }
}
