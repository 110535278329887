@import "../../theme/vars";

.snack-content {

  &.success { background-color: $success-color; }
  &.warning { background-color: $warning-color; }
  &.info { background-color: $info-color; }
  &.error { background-color: $error-color; }

  display: flex;
  align-items: flex-start;


  .snack-content-message {
    flex: 4 4 80%;
  }
  .snack-content-action {
    flex: 0 0 48px;
  }

  .snack-content-msg-inner {
    display: flex;
    align-items: flex-start;
    .snack-content-inner-icon {
      flex: 0 0 20px;
      font-size: 20;
      opacity: 0.9;
      margin-right: 16px;
    }
    .snack-content-inner-msg {
      flex: 2 2 90%;
    }
  }

  .snack-content-close-btn {

  }
  .snack-content-close-icon {

  }
}
