
.main-loading-container {
  z-index: 20000;
  background-color: rgba(255,255,255, 0.6);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;

  .progress-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
