
.klara-dialog-form {
  @media (min-width: 900px) {
    min-width: 600px;
    box-sizing: border-box;
  }
}

.admin-user-messages {
  margin-top: 24px;
  .snack-content {
    max-width: none !important;
  }
}

.user-table {
  .u-display-name {
    font-size: 15px;
    padding-bottom: 2px;
  }
  .u-email {
    color: #999;
  }
}

.dialog-corner-close-btn {
  position: absolute !important;
  right: 12px;
  top: 8px;
  color: #aaa;
}
