
.image-field {
  margin-top: 30px;
  position: relative;
  margin-bottom: 30px;

  .field-label {
    font-weight: bold;
    font-size: 14px;
  }

  .img-preview-holder {
    padding-top: 10px;
  }

  &.image-field-error {
    color: #f44336;
  }
}
