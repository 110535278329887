.fuente-container {
  margin: 10px 0px;
  margin-bottom: 80px !important;

  .fuente-edit-btn {
    float: right;
    position: relative;
    top: -4px;
  }

  .h1-title {
    margin-bottom: 5px;
  }

  .download-btn-container {
    margin: 16px 0;
  }
}
