

.host-form-main-container {

  .host-form-main-paper {
    padding: 24px;
    margin-top: 20px;
    margin-bottom: 100px;
  }

  @media (max-width: 600px) {
    padding-left: 0 !important;
    padding-right: 0 !important;
    .host-form-main-paper {
      box-shadow: none !important;
      padding: 20px;
    }
  }
}
