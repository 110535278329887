

.dashboard-container {

    @media (max-width: 420px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        h1 {
            margin-left: 16px;
        }
    }

    .dashboard-content {

        .dashboard-section {
            background-color: white;
            padding: 16px;
            //border-radius: 8px;
            //margin-bottom: 30px;
            margin-bottom: 32px;
        }

        .numbers-section {
            .numbers-top {
                padding-bottom: 16px;
                display: flex;
                align-items: center;
                h2 {
                    margin: 0;
                }
                .grow {
                    flex: 5 1 auto;
                }
                .num-items-field {
                    margin: 0 16px;
                }
            }
        }
    }
}
