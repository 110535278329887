
.ch-simple-select-menu-item.with-subheader {
  position: relative;
  margin-top: 44px;
  overflow: visible !important;
  .ch-simple-select-subheader {
    position: absolute;
    top: -34px;
    background-color: transparent !important;
    cursor: default !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    line-height: 42px !important;
    padding-top: 0px !important
  }
  &.is-first {
    margin-top: 32px !important;
  }
}

.ch-simple-select-input {
  padding: 12px 30px 12px 10px !important;
  .ch-simple-select-subheader { display: none !important}
}

.ch-simple-select-dd-icon {
  right: 5px !important;
}
