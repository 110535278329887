.locations-view-container {

    .title-holder {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .add-new-holder {
            margin-left: 16px;
            padding: 0px 0;
        }
    }

    .loc-sec-item {
        padding: 10px 0 0 0;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .sec-item {
            margin-right: 10px;
            white-space: nowrap;
            min-height: 24px;
            line-height: 24px;
            margin-bottom: 10px;
            max-width: 120px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .location-list-name {
        font-size: 17px;
    }

    .filters {
        margin-bottom: 20px;
        .filter-chip {
            margin-right: 6px;
        }
    }
}

.filter-control {
    display: flex;
    flex-wrap: wrap;
    .search-field {
        margin-top: 10px;
        margin-right: 16px;
    }
    .checkboxes {
        margin-top: 10px;
        overflow: hidden;
        white-space: nowrap;
    }
}
