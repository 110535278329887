
.fuente-main-tabs {
    background-color: #eee;
}

.fuente-main-tabs-container {
    @media (max-width: 470px) {
        margin-left: -16px;
        margin-right: -16px;
    }
}
