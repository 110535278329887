
.periodpicker {
    padding-bottom: 40px;
    display: block !important;
    .periodpicker-items {
        padding: 10px 0;
        .periodpicker-pair {
            display: flex;
            .periodpicker-date-picker {
                margin-right: 20px;
            }
        }
    }
    .periodpicker-add-pair {

    }
}
