

.fuente-status-indicators {
    display: flex;
    align-items: center;
    padding: 0px 0 20px;
    .indicator {
        padding-right: 16px;
        &:last-child {
            padding-right: 0;
        }
    }
}
