

.date-range-field {
    &.MuiFormControl-root {
        min-width: 240px;
    }
}

.date-field-cal-popup-content {
    display: flex;
    .rdrDefinedRangesWrapper {
        width: 160px;
    }
    .date-range-cal-container {
        max-width: 332px;
        .rdrDateDisplayWrapper {
            background-color: white;
        }
    }
}
