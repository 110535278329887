
.radio-field {

  display: flex !important;
  margin-top: 20px;

  .radio-field-group-label-bottom {
    margin-left: -16px;
    margin-right: -16px;
  }

  .radio-error-msg {
    margin-top: 0;
    margin-bottom: 10px;
  }

  .radio-field-label {
    padding-top: 20px;
    margin-bottom: 5px;
  }

  .radio-item-label-bottom {
    max-width: 80px;
    //min-width: 100px;
    text-align: center;
    span {
      font-size: 14px !important;
    }
  }
}
