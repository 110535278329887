
.location-about-tab {
    padding: 40px 0;
    h3 {
        text-align: center;
    }

    .picture-and-map {
        display: flex;
        align-items: center;
        .pic-and-map-item {
            flex: 1 1 50%;
            max-width: 50%;
            aspect-ratio: 3/2;
            margin: 10px;
        }
        .loc-img {
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }
    }
    @media (max-width: 720px) {
        .picture-and-map {
            flex-wrap: wrap;
            .pic-and-map-item {
                width: calc(100% - 20px);
                max-width: calc(100% - 20px);
            }
        }
    }

    .location-fields {
        padding: 30px 10px;
        h3 {
            text-align: left;
        }
    }
}
