
.qr-generator {
    padding: 24px;

    .qr-generator-alert {
        margin-bottom: 16px;
    }
    .qr-generator-toolbar {
        .txt-field {
            margin-right: 20px;
        }
        .btn {
            margin-right: 20px;
        }
    }

    .qr-container {
        width: 75px;
        height: 75px;
        display: block;
        float: left;
        margin: 16px;
    }
}


@media print {
    @page {
        size: auto;
        margin: 0;
        padding: 0;
    }

    body {
        padding: 0.6cm
    }

    .klara-main-container {
        margin: 0 !important;
        padding: 0 !important;
    }

    .main-app-bar {
        display: none;
    }
    .qr-generator {
        padding: 0;

        .qr-generator-controls {
            display: none;
        }

        .qr-container {
            width: 2.7cm;
            height: 2.7cm;
            display: block;
            float: left;
            margin: 0.6cm;
        }
    }
}
