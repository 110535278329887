.autotest-form {
  .test-results {
    padding-top: 0px;
    .failed-required-fields {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      .emoji {
        flex: 0 0 80px;
        font-size: 40px;
        text-align: center;
        padding-top: 5px;
      }
      .messages {
        flex: 1 1 auto;
        padding-left: 0px;
        font-size: 16px;
        p {
          margin-bottom: 8px;
          margin-top: 8px;
        }
      }
    }
    .evaluation-results {
      .gauge-container {
        position: relative;
        max-width: 400px;
        margin-left: auto;
        margin-right: auto;
        #gauge {
          position: relative;
          z-index: 1;
        }
        .gauge-emoji {
          position: absolute;
          bottom: 5px;
          border-top: 2px solid #efefef;
          left: 50%;
          transform: translateX(-50%);
          width: 65px;
          height: 65px;
          background-color: white;
          border-radius: 50%;
          z-index: 2;
          font-size: 40px;
          text-align: center;
          line-height: 70px;
        }
      }
      .evaluation-msg {
        max-width: 500px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        font-size:  16px;
      }
    }
  }
  .seguir-action {
    padding-top: 30px;
    text-align: center;
  }

  .radio-field, .multi-checkbox-field {
    margin-top: 40px !important;
  }
}
