
.servidas-tab {
    .servida-tab-controls {
        padding-top: 10px;
        padding-bottom: 25px;
    }
    .servidas-list {
        .serv-icon {
            width: 25px;
            height: 25px;
        }
    }
}
