
.comandos-tab {
    .comando {
        padding: 16px;
        margin-bottom: 30px;
        h3 {
            margin-top: 0;
            padding-top: 0px;
        }
    }
}
